<template>
  <v-container fluid>
    <v-row class="mt-1">
      <v-col>
        <btn-voltar
          v-if="$store.getters['usuario/temPermissao']('super.administrador')"
          to="/empresas"
        />
      </v-col>
    </v-row>
    
    <empresas-campos
      :carregando-salvar="carregandoSalvar"
      :validacao-formulario="validacaoFormulario"
      :empresa="empresa"
      @salvar="salvar"
    />

  </v-container>
</template>

<script>
import empresas from "@/api/empresas";
import _ from "lodash";

export default {
  components: {
    EmpresasCampos: () => import("./EmpresasCampos"),
  },

  data() {
    return {
      carregandoSalvar: false,

      validacaoFormulario: {},
      empresa: {},
      tab: null,
    };
  },

  mounted() {
    this.buscar();
  },

  methods: {
    async buscar() {
      this.$store.commit(`layout/SET_LOADING`, true);
      empresas
        .buscar(this.$route.params.id)
        .then((resposta) => {
          var empresa = {
            nome: resposta.data.data.pessoa.nome,
            cpf_cnpj: resposta.data.data.pessoa.cpf_cnpj,
            contato: resposta.data.data.pessoa.contato,
            apelido: resposta.data.data.pessoa.apelido,
            telefones: resposta.data.data.pessoa.telefones,
            plano_id: resposta.data.data.plano_id,
            id: resposta.data.data.id,
            ativo: resposta.data.data.ativo,
            email: resposta.data.data.pessoa.email,
            tipo_pessoa: resposta.data.data.pessoa.tipo_pessoa,
            url_imagem: resposta.data.data.url_imagem,
            imagem_logo: null,
          };
          var endereco = {
            "endereco.id": null,
          };

          if (!_.isEmpty(resposta.data.data.enderecos[0])) {
            endereco = resposta.data.data.enderecos[0];
            endereco["endereco_id"] = endereco.id;
          }

          empresa.endereco = endereco;
          this.empresa = empresa;
        })
        .catch((e) => {
          console.log(e);
          this.$router.push("/empresas", () =>
            this.$snackbar.mostrar({
              cor: "error",
              mensagem: this.$erroApi._(e),
            })
          );
        })
        .finally(() => {
          this.$store.commit(`layout/SET_LOADING`, false);
        });
    },

    async salvar(empresa) {
      this.carregandoSalvar = true;

      try {
        await empresas.atualizar(empresa.id, empresa);
        if (
          this.$store.getters["usuario/temPermissao"]("super.administrador")
        ) {
          this.$router.push("/empresas", () =>
            this.$snackbar.mostrar({
              cor: "success",
              mensagem: this.$mensagens._("sucesso_editar"),
            })
          );
        }
        this.$snackbar.mostrar({
          cor: "success",
          mensagem: this.$mensagens._("sucesso_editar"),
        });
      } catch (e) {
        if (e.response.status === 422) {
          this.validacaoFormulario = e.response.data.erros;
          return;
        }

        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
        return;
      } finally {
        this.carregandoSalvar = false;
      }
    },
  },
};
</script>
